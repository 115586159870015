<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!!address">
			<payment-logo :custom-logo="'crypto.svg'" />
			<bitstamp-address
				v-if="!!address"
				:address="address"
				:account-currency="accountCurrency"
				:currency="form.method"
			/>
		</div>

		<div v-else>
			<div class="cashier-box cashier-box--deposit">
				<payment-logo :custom-logo="'crypto.svg'" />
				<div class="small-width overflow-visible">
					<payment-wallet />
				</div>

				<div class="line-separator-wrapper">
					<div class="line-separator" />
				</div>

				<div class="small-width">
					<select
						v-model="form.method"
						class="cashier-form-control"
						:placeholder="[[$t('deposit.methods')]]"
						@change="onMethodChange()"
					>
						<option
							v-for="method in methods"
							:key="method.id"
							:value="method.id"
							:class="{ active: form.method === method.id }"
						>
							{{ method.name }}
						</option>
					</select>
				</div>

				<div class="info-description">
					<div class="input-group account-wrapper">
						<label>{{ $t('deposit.amount') }}</label>
						<div class="form-wrapper">
							<div class="amount-values-wrapper">
								<p class="unsupported">
									{{
										$t('deposit.amount-crypto', {
											currency: methods.find((m) => m.id === form.method).currency,
										})
									}}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div class="info-description">
					<div class="input-group account-wrapper">
						<label>{{ $t('deposit.promocode') }}</label>
						<div class="form-wrapper">
							<div class="amount-values-wrapper">
								<p class="unsupported">
									{{ $t('deposit.promocode-unsupported') }}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div v-if="exchangeRate" class="info-description">
					<h2 class="exchange-title">
						{{ $t('deposit.exchange-rate') }}
					</h2>
					1 {{ methods.find((m) => m.id === form.method).symbol }} = {{ exchangeRate }} USD
				</div>

				<button type="button" class="button primary-button deposit center" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>

			<div class="method-description-cashier">
				{{ $t(`deposit.method-descriptions.coinspaid`) }}
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const bitstampAddress = () => import('./BitstampAddress');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositBitstamp',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		bitstampAddress,
		paymentWallet,
	},
	data() {
		return {
			address: null,
			accountCurrency: null,
			form: {
				method: 'BTC',
			},
			methods: [
				{
					id: 'BTC',
					name: 'Bitcoin',
					symbol: 'BTC',
					calculateExchangeRate: true,
				},
			],
			loading: false,
			exchangeRate: null,
		};
	},
	async mounted() {
		await this.fetchExchangeRate(this.form.method);
	},
	methods: {
		async getAddress(method) {
			const response = await apiClient.getAddressBitstamp(method);

			if (response.status === 200 || response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from get address');
		},
		async fetchExchangeRate(method) {
			const response = await apiClient.fetchExchangeRateBitstamp(method);

			if (response.status === 200) {
				this.exchangeRate = response.data.exchangeRate.toFixed(2);
			} else {
				throw new Error('Failed to load exchange rate');
			}
		},
		async pay() {
			this.loading = true;

			try {
				const response = await this.getAddress(this.form.method);

				this.address = response.address;
				this.accountCurrency = response.accountCurrency;
			} catch (e) {
				console.error(e);

				this.$router.push({
					path: '/payments/deposit/confirmation',
					query: { status: 'error' },
				});
				console.error('Failed to fetch user data', e);
			} finally {
				this.loading = false;
			}
		},
		async onMethodChange() {
			const method = this.methods.find((m) => m.id === this.form.method);
			this.exchangeRate = null;

			if (method.calculateExchangeRate) {
				await this.fetchExchangeRate(this.form.method);
			}
		},
	},
};
</script>
<style scoped>
p.unsupported {
	text-align: left;
}

.info-description {
	padding: 10px;
	margin-top: 15px;
}

.cashier-box {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	padding: 20px;
}

.primary-button.deposit {
	margin-top: 20px;
}

.exchange-title {
	padding: 0px;
	margin: 5px;
	font-weight: lighter;
}
</style>
